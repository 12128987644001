.WaitlistModal {
    position: fixed;
    max-height: 80dvh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(10px);
    border-radius: 2rem;
    border-style: none;
    color: var(--text-color);
    z-index: 999999;
    overflow: scroll;
    pointer-events: all;

    @media (max-width: 768px) {
        width: 80%;
    }
}

.ModalButtonContainer {
    display: flex;
    flex-direction: row-reverse;
}

.ModalExitButton {
    cursor: pointer;
    background-color: transparent;
    border-style: none;
}

.ModalExitButton:hover {
    background-color: unset;
}

.ConfirmationModal {
    width: 95%;
    margin: auto;

    .ConfirmationModalButtonContainer {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5vw;
        padding-bottom: 1dvh;
    }
}