.AllCriteriaContainer {
    min-height: 45vh;
    max-height: 100vh;
    overflow: scroll;
    scroll-snap-type: y mandatory;

    @media (max-width: 768px) {
        /* height: 62vh; */
    }
}

.ButtonContainer {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 5vh;

    @media (max-width: 768px) {
        width: 90%;
    }
}