.Select {
	width: 100%;
	cursor: pointer;
	box-sizing: border-box;
	color: var(--text-color);
	padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
	font-size: 1.5rem;
	text-align-last: center;
	background-color: var(--bg-color-2);
	border-width: 1px;
    border-style: solid;
    border-color: var(--text-color-3);
	-webkit-border-radius: 0.5rem;

	appearance: none;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="rgb(254, 254, 254)" d="M7 10l5 5 5-5z"/></svg>');
	background-repeat: no-repeat;
	background-position: right 0.5rem center;

	@media (max-width: 768px) {
		font-size: 1.2rem;
	}
}

.Select:focus {
	outline: none;
	border-color: var(--cta-color);
}

.Select:hover {
    border-color: var(--cta-color);
}

::placeholder {
    color: var(--text-color);
    opacity: 0.35; /* Firefox */
}

.CTA {
    color: var(--text-color);
    background-color: var(--bg-color-2);
}

.CTA:hover {
    color: var(--text-color)
}

.Primary {
    color: var(--text-color);
    background-color: var(--bg-color-2);
}

.Primary:hover {
    color: var(--text-color)
}

.Secondary {
	color: var(--text-color);
    background-color: var(--bg-color);
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
}

.Secondary:hover {
    border-color: var(--text-color-2);
}

option {
	background-color: var(--bg-color);
	text-align: center;
}
