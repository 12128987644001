.progress-bar-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-style: solid;
    border-color: var(--bg-color-2);
    border-radius: 10px;
    border-width: 1px;
    overflow: hidden;

    .progress-bar {
        height: 100%;
        background-color: var(--text-color);
        width: 0;
        transition: width 0.3s ease;
    }
}

