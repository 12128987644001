.PrivacyPolicyContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8dvh;
    margin-bottom: 5dvh;
    color: var(--text-color);
    text-align: justify;

    @media (max-width: 768px) {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        text-align: center;
    }

    h2 {
        text-align: center;
    }

    h3 {
        text-align: center;
    }

    h4 {
        text-align: center;
    }
}
