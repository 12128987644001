.container {
    display: flex;
    background-color: var(--bg-color-2);
    border-radius: 1rem;
    overflow: hidden;
}

/* Vertical Layout (Default) */
.vertical {
    flex-direction: column;
    width: min-content;
}

/* Horizontal Layout */
.horizontal {
    flex-direction: row;
    width: auto; /* Allow horizontal expansion */
}

/* Shared Option Style */
.option {
    text-wrap: nowrap;
    flex: 1;
    padding: 20px;
    text-align: center;
    color: var(--cta-color);
    font-family: var(--font-focus);
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.horizontal .option {
    padding: 8px 12px;
    font-size: 1.25rem;
    flex: 1 1 auto;
    text-wrap: balance;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    overflow: hidden;
}

/* Divider for Vertical Layout */
.vertical .option:not(:last-child) {
    border-bottom: 1px solid var(--text-color-3);
}

/* Divider for Horizontal Layout */
.horizontal .option:not(:last-child) {
    border-right: 1px solid var(--text-color-3);
}

/* Hover Effect */
@media (hover: hover) {
    .option:hover {
        background-color: var(--text-color-3);
    }
}

/* Active Option */
.active {
    background-color: var(--cta-color);
    color: var(--bg-color);
    cursor: default;
}