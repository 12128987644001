.App {
	/* height: 100vh; */
	width: 100vw;
	background-color: var(--bg-color);
	text-align: center;
	overflow: scroll;

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.WaitlistModal {
    position: fixed;
    width: 50%;
    translate: 0% 50%;
	background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
	border-radius: 2rem;
	border-style: none;
	color: var(--text-color);
    z-index: 999999;

	@media (max-width: 768px) {
		width: 80%;
		translate: 0% 15%;
	}

	h2 {
		margin-top: 0;
		padding-top: 0;
	}

	p {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		font-size: 1.2rem;
		text-align: left;

		@media (max-width: 768px) {
			font-size: 1rem;
		}

		small {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.WaitlistModalContainer {
	margin-top: 2vh;
	margin-bottom: 2vh;
	margin-left: 2vh;
	margin-right: 2vh;

	.WaitlistModalSmallContainer {
		display: flex;
		flex-direction: column;

		span {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.ModalButtonContainer {
	display: flex;
	flex-direction: row-reverse;
}

.ModalButton {
	cursor: pointer;
	background-color: transparent;
	border-style: none;
}

.ModalButton:hover {
	scale: 125%;
}

.WaitlistForm {
	display: grid;
	grid-template-columns: auto;
	row-gap: 2vh;
	padding-top: 2vh;
	padding-bottom: 2vh;
	margin-bottom: 2vh;

	.Tooltip {
		width: max-content;
		border-radius: 2rem;
		p {
			font-size: 0.8rem;
		}
	}
}

.SelectInputLeftSelectContainer {
	display: flex;
	flex-direction: row;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1vw;
	padding-right: 1vw;
	justify-content: space-between;

	label {
		font-size: 1.2rem;

		@media (max-width: 768px) {
			font-size: 1rem;
		}
	}
}

.SelectInput {
	width: 60%;
	cursor: pointer;
	box-sizing: border-box;
	border: none;
	-webkit-border-radius: 0.5rem;
	color: var(--text-color);
	font-size: 1.2rem;
	text-align-last: center;
	background-color: rgba(255, 255, 255, 0.25);
	border-bottom: 2px solid var(--bg-color-2);
	backdrop-filter: blur(10px);
	appearance: none;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="rgb(69, 196, 111)" d="M7 10l5 5 5-5z"/></svg>');
	background-repeat: no-repeat;
	background-position: right 0.5rem center;
	padding-right: 1.5rem;

	@media (max-width: 768px) {
		font-size: 1rem;
	}
}

.SelectInput:focus {
	outline: none; /* Removes the default focus outline */
	border-bottom: 2px solid var(--cta-color); /* Example: Changes bottom border color on focus */
}

.EmailInput {
	width: 90%;
	margin: auto;
	font-size: 1.2rem;
	text-align: center;
	cursor: pointer;
	color: var(--cta-color);
	background-color: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(10px);
	border-style: none;
	border-radius: 0.5rem;

	@media (max-width: 768px) {
		font-size: 1rem;
	}
}

.EmailInput:focus {
	outline: none; /* Removes the default focus outline */
	border-bottom: 2px solid var(--cta-color); /* Example: Changes bottom border color on focus */
}

.FormSubmit {
	cursor: pointer;
	width: min-content;
	margin: auto;
	font-size: 1.2rem;
	color: var(--cta-color);
	background-color: rgba(var(--pos-values), 0.25);
	border-style: none;
	border-radius: 1rem;

	@media (max-width: 768px) {
		font-size: 1.2rem;
	}
}

.FormSubmit:hover {
	scale: 125%;
}

.FormSubmit:disabled {
	color: var(--neg-color);
	scale: 100%;
	background-color: rgba(var(--neg-values), 0.25);
}