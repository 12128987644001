.Button {
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.5rem;
    border-style: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.Button:disabled {
    border-style: solid;
    border-width: 1px;
    border-color: var(--neg-color);
    background-color: rgba(var(--neg-values), 0.5);
    cursor: unset;
}

.Button:disabled:hover {
    scale: unset;
}

.CTA {
    color: var(--bg-color);
    background-color: var(--cta-color);
}

.CTAHover:hover {
    scale: 110%;

    @media (max-width: 768px) {
        scale: 100%;
    }
}

.Primary {
    color: var(--bg-color);
    background-color: var(--text-color);
}

.PrimaryHover:hover {
    color: var(--bg-color);
    background-color: var(--cta-color);
    border-color: var(--cta-color);
}

.Secondary {
    color: var(--text-color);
    border-style: solid;
    border-width: 1px;
    border-color: var(--text-color-3);
    background-color: var(--bg-color-2);
}

.SecondaryHover:hover {
    background-color: var(--bg-color);
    border-color: var(--text-color-2);
}

.Warn {
    color: var(--bg-color);
    background-color: var(--neg-color);
}

.WarnHover:hover {
    color: var(--text-color);
}
