.Container {
    min-height: 100vh;
    margin-top: 8vh;
    padding-bottom: 8vh;

    background-color: var(--bg-color-2);
}

.ButtonContainer {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    /* column-gap: 5vw; */
    /* max-width: 50%; */
    margin: auto;

    @media (max-width: 768px) {
        /* min-width: 90%; */
    }
}