.LoanInfoForm {
    width: 80vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    margin-left: auto;
    margin-right: auto;
    gap: 2vw;
    row-gap: 2vh;
    align-items: end;
    /* mobile */
    @media (max-width: 768px) {
        width: 95vw;
        grid-template-columns: 1fr 2fr;
    }
}

.DualInputSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.InputSection {
    width: 80%;
    margin: auto;
    margin-bottom: 5vh;

}

.InputTitle {
    text-align: center;
    color: var(--text-color);
    /* mobile */
    @media (max-width: 768px) {
        font-size: 1rem;
    }
}

.LoanDetailsReadout {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
}

.ChartContainer {
    height: 40vh;
    margin-bottom: 5vh;
    padding-bottom: 5vh;

    .ChartTitle {
        text-align: center;
    }

    .customTooltip {
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 10px;
        padding: 10px;
        font-size: 14px;
    }
      
    .label {
        font-weight: bold;
        text-align: center;
    }
      
    .tooltipList {
        list-style: none;
        padding: 0;
        margin: 0;
    }
      
    .tooltipList li {
        margin: 4px 0;
        text-align: left;
    }
}

.ButtonContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    margin-bottom: 5vh;

    small {
        color: var(--text-color-2);
        padding-top: 1vh;
    }
}

.Disclaimer {
    text-align: justify;
    color: var(--text-color-3);
}