.Input {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.5rem;
    font-family: Helvetica;
    text-align: center;
    cursor: pointer;
    background-color: var(--bg-color-2);
    border-width: 1px;
    border-style: solid;
    border-color: var(--text-color-3);
    border-radius: 0.5rem;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
}

.Input::-webkit-outer-spin-button,
.Input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Input::-webkit-datetime-edit {
    text-align: center;
}

.Input:focus {
    outline: none;
    border-color: var(--cta-color);
}

.Input:hover {
    border-color: var(--cta-color);
}

.Input:disabled {
    /* background-color: var(--neg-color); */
    border-color: var(--neg-color);
    cursor: not-allowed;
}

.Primary {
    color: var(--text-color);
    background-color: var(--bg-color-2);
}

.Primary:hover {
    color: var(--text-color)
}

.Secondary {
    color: var(--text-color);
    background-color: var(--bg-color);
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
}

.Secondary:hover {
    border-color: var(--text-color-2);
}

.Checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid var(--text-color-3);
    border-radius: 4px;
    background-color: var(--bg-color);
    cursor: pointer;
    position: relative;
}
  
.Checkbox:checked {
    /* background-color: var(--cta-color); */
    border-color: var(--cta-color)
}
  
.Checkbox:disabled {
    background-color: var(--neg-color);
    border-color: var(--neg-color);
    cursor: not-allowed;
}
  
.Checkbox:hover:not(:disabled) {
    background-color: var(--bg-color-2);
}
  
.Checkbox:checked::after {
    content: '';
    position: absolute;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--text-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.Checkbox:focus {
    outline: none;
    box-shadow: 0 0 3px 2px rgba(0, 123, 255, 0.25);
}

.RangeContainer {
    width: 100%;
    position: relative;
}

.RangeSlider {
    width: 90%;
    /* height: 100%; */
    min-height: 25px;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-appearance: none;
    appearance: none;
    background: var(--bg-color-2);
    border: 1px solid var(--text-color-3);
    border-radius: 0.5rem;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.RangeSlider:hover {
    opacity: 1;
}
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.RangeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 0.5rem;
    background: var(--text-color);
    cursor: pointer;
}
  
.RangeSlider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: var(--text-color);
    cursor: pointer;
}

.RangeLabel {
    position: absolute;
    bottom: 45px; /* Position the label above the slider */
    transform: translateX(-50%);
    background-color: var(--bg-color-2);
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
}

.DualRangeContainer {
    width: 100%;
    min-height: 25px;
    position: relative;
    display: flex;
}

.RangeTrack {
    position: absolute;
    height: 25px;
    width: 100%;
    border-radius: 0.5rem;
    background-color: transparent;
    pointer-events: none; /* Prevent any interaction */
}

.DualRangeSlider {
    width: 100%;
    height: 25px;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid var(--text-color-3);
    border-radius: 0.5rem;
    outline: none;
    opacity: 0.7;
    position: absolute;
    pointer-events: none;
    transition: opacity 0.2s;
}

.DualRangeSlider:hover {
    opacity: 1;
}

/* Styling for slider thumbs */
.DualRangeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 0.5rem;
    background: var(--text-color);
    cursor: pointer;
    pointer-events: auto; /* Enable interaction with thumbs */
}

.DualRangeSlider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: var(--text-color);
    cursor: pointer;
    pointer-events: auto; /* Enable interaction with thumbs */
}

.DualRangeLabel {
    position: absolute;
    bottom: 30px;
    transform: translateX(-50%);
    background-color: var(--bg-color-2);
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
}