.BankManagementContainer {
    /* width: 80vw; */
    /* margin: auto; */
    padding-top: 3dvh;
    padding-bottom: 3dvh;
    overflow: hidden;
}

.ButtonContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.EnableDashboard {
    padding-top: 3dvh;
    padding-bottom: 3dvh;
}

.BanksAllContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;

    @media (max-width: 768px) {
        grid-template-columns: auto;
    }
}

.BankContainer {
    width: 100%;
    position: relative;

    .BankNameRemoveContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .RemoveBankButton {
            cursor: pointer;
            background-color: transparent;
            border-style: none;
            margin-left: 2vw;
            opacity: 50%;
        }
    
        .RemoveBankButton:hover {
            scale: 110%;
        }
    }
}

.AccountAllContainer {
    position: relative;
}

.AccountContainer {
    position: relative;
    height: 15vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    min-height: 10vh;
    margin: auto;
    border-style: none;
    border-radius: 2rem;
    border-width: 1px;
    border-color: var(--text-color-3);

    @media (max-width: 768px) {
        width: 90%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border-radius: inherit;
        padding: 1px;
        background: linear-gradient(210deg, rgba(var(--accent-color-1), 0.5) 0%, transparent 50%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    @media (max-width: 768px) {
        height: 20vh;
    }
}

.AccountTitle {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.2rem;
}

.AccountInputContainer {
    margin-top: auto;
    margin-bottom: auto;
}

.PositiveP {
    font-size: 0.9rem;
    color: var(--text-color)
}

.NegativeP {
    font-size: 0.9rem;
    color: var(--neg-color)
}

.LoadingIconContainer {
    margin-top: 5vh;
    margin-bottom: 5vh;
}
