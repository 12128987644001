.BorrowHomeContainer {
    min-height: 100vh;
    max-width: 100vw;
    /* display: flex; */
    /* flex-direction: column; */
    margin-top: 10dvh;

    /* mobile */
    @media (max-width: 768px) {
        /* margin-left: auto;
        margin-right: auto; */
    }

    .ApplicationProgressBar {
        height: 2vh;
        width: 80vw;
        margin-top: 5vh;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8vh;
    }
}
