.SignupModal {
    position: fixed;
    max-height: 80dvh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 50%;
    background-color: var(--bg-color);
    border-style: none;
    border-radius: 3rem;
    box-shadow: -1px -1px 8rem 6px var(--bg-color-2), 1px 1px 8rem 6px var(--bg-color-2);
    /* on behind menu popup */
    z-index: 99998;
    color: var(--text-color);

    @media (max-width: 768px) {
        width: 80%;
        translate: 0% 15%;
    }

    .InsideContainer {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 768px) {
            width: 90%;
        }

        .Close {
            width: 2rem;
            margin-left: auto;
            background: transparent;
            border: none;
            color: var(--neg-color);
            font-size: 1rem;
        }

        .Close:hover {
            scale: 110%;
            cursor: pointer;
        }
    }

    .HeadingText {
        color: var(--text-color);
        margin-bottom: 5vh;
    }

    .Form {
        display: flex;
        flex-direction: column;
        margin-bottom: 2vh;

        @media (max-width: 768px) {
            margin-bottom: 5vh;
        }
    }

    .Input {
        margin-bottom: 3vh;
    }

    .Submit {
        width: 50%;
        background-color: transparent;
        /* margin-top: 5vh; */
        margin-left: auto;
        margin-right: auto;
        font-size: 2rem;
        color: var(--neg-color);
        border-style: none;
        border-radius: 3rem;
        cursor:pointer;

        @media (max-width: 768px) {
            width: 90%;
        }
    }

    .CreateNewAccount {
        margin-top: 2vh;
        margin-bottom: 2vh;
        cursor:pointer;
        color: var(--cta-color);
        text-decoration: underline;

        @media (max-width: 768px) {
            margin-bottom: 4vh;
        }
    }

    .CreateNewAccount:hover {
        scale: 105%;
    }

    .ForgotPassword {
        color: var(--text-color);
        cursor:pointer;
    }

    .ForgotPassword:hover {
        scale: 105%;
    }
}
