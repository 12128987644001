.Container {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 2vw;
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: 768px) {
    }
}

.Tooltip {
    max-width: 100vw;
    z-index: 999999;
    background-color: var(--bg-color);
    opacity: 1;
    border-radius: 1rem;
}