.LendHomeContainer {
    min-height: 100vh;
    margin-top: 8vh;

    .LenderLoanViewScrollContainer{
        max-height: 80vh;
        min-height: 40vh;
        width: 80vw;
        margin-top: 2vh;
        margin-bottom: 2vh;
        margin-left: auto;
        margin-right: auto;
        overflow-x: hidden;
        overflow-y: scroll;

        .LoanViewContainer {
            margin-top: 2vh;
            margin-bottom: 2vh;
            margin-left: 6px;
            margin-right: 6px;
            border: none;
            border-radius: 3rem;
            box-shadow: -1px -1px 4px 2px var(--bg-color-2), 1px 1px 4px 2px var(--bg-color-2);

            .ProgressContainer {
                height: 1vh;
                width: 60vw;
                margin-left: auto;
                margin-right: auto;
                padding-top: 2vh;

                @media (max-width: 768px) {
                    height: 2vh;
                }
            }

            .TitleContainer {
                h2 {
                    margin-bottom: 0;
                }

                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .DataContainer {
                display: flex;
                flex-direction: row;
                width: 50vw;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;

                @media (max-width: 768px) {
                    flex-direction: column-reverse;
                    width: 80vw;
                }

                .ChartContainer {
                    height: 20vh;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;

                    @media (max-width: 768px) {
                        height: 30vh;
                    }
                }
            }
        }
    }
}
