.App-footer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    text-align: center;
    color: var(--text-color);
    font-weight: bold;
    /* scroll-snap-align: start; */
    /* background-color: var(--bg-color); */
    backdrop-filter: blur(10px);
    background-color: rgb(0, 0, 0, 0.3);
}

.Footer-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1%;
    z-index: 99999;
}

.Footer-column {
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    text-align: center;
}

.Pages {
    margin-top:2%;
}

.Link {
    color: var(--text-color);
}

.DiscloserContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3vh;
    text-align: justify;
    z-index: 99999;
}