.HighOverviewContainer {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 80%;
    margin: auto;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    border-radius: 2rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border-radius: inherit;
        padding: 1px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    @media (max-width: 768px) {
        width: 85%;
    }

    .HighOverviewContainerDataContainer {
        /* display: grid; */
        /* grid-template-columns: 1fr 1fr; */
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: end;
        padding-top: 0.5vh;
        text-align: left;

        @media (max-width: 768px) {
            margin-left: 5vw;
            margin-right: 5vw;
        }

        h1 {
            padding: 0;
            margin: 0;
            font-size: 1.5rem;
        }

        h3 {
            padding: 0;
            margin: 0;
            color: var(--text-color-2);
        }
    }
}

.HighOverviewContainer.income::before {
    background: linear-gradient(210deg, rgba(var(--accent-values-2), 1) 0%, transparent 50%);
}

.HighOverviewContainer.expense::before {
    background: linear-gradient(210deg, rgba(var(--cta-values), 1) 0%, transparent 50%);
}

.HighOverviewContainer.balance::before {
    background: linear-gradient(210deg, rgba(var(--accent-values-3), 1) 0%, transparent 50%);
}

.HighOverviewContainer.savings::before {
    background: linear-gradient(210deg, rgba(var(--accent-values-1), 1) 0%, transparent 50%);
}

.LiabilitiesOverviewWrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 80%;
    margin: auto;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    border-radius: 2rem;
    background: linear-gradient(210deg, rgba(var(--accent-values-1), 0.7) 0%, transparent 30%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border-radius: inherit;
        padding: 1px;
        background: linear-gradient(210deg, rgba(var(--accent-values-1), 1) 0%, transparent 50%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }

    @media (max-width: 768px) {
        /* height: 68%; */
        width: 85%;
    }
}

.CreditCardSVGContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    /* transform: scale(1.5, 1.3); */
    pointer-events: none;
}

.LiabilitiesOverviewContainer {
    height: 100%;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scroll-snap-stop: always;
}

.LiabilitiesCardContainer {
    max-height: 100%;
    min-height: 100%;
    scroll-snap-align: start;

    .CardLiability {
        position: relative;
        z-index: 1;
        width: 55%;
        margin-top: 1.15vh;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5vw;
        
        .ColumnLeft {
            text-align: right;
        }

        .ColumnRight {
            text-align: left;
        }
    }

    h1 {
        padding: 0;
        margin: 0;
        padding-top: 1.5vh;
        color: var(--text-color-2);
        font-size: 1.3rem;
    }

    span {
        color: var(--text-color-2);
        font-size: 1rem;
    }

    h2 {
        padding: 0;
        margin: 0;
        margin-top: 1.2vh;
        font-size: 1.3rem;
    }

    h3 {
        padding: 0;
        margin: 0;
        margin-top: 1vh;
    }

    h4 {
        padding: 0;
        margin: 0;
        font-size: 1.1rem;
        margin-top: 1.2vh;
    }

    p {
        margin: 0;
        padding: 0;
    }
}
