.Header {
    position: fixed;
    height: 8vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    backdrop-filter: blur(10px);
    z-index: 99999;
}

.HeaderLogoContainer {
    max-height: 8vh;
    overflow: hidden;
	width: 20%;
    z-index: 99999;
    padding-left: 1vw;
    padding-top: 1vh;

    .HeaderLogoCompany {
        position: absolute;
        top: 10px;
        margin: 0;
        padding: 0;
        padding-left: 1px;
        font-family: var(--font-focus);
        z-index: 99999;
    }

    .HeaderLogoService {
        position: absolute;
        top: 15px;
        margin: 0;
        padding: 0;
        color: var(--cta-color);
        font-size: 5vh;
        font-family: var(--font-focus);
        text-decoration: none;
        cursor: pointer;
        z-index: 99998;
    }
}

.HeaderLogoSVG {
    height: 100%;
    min-height: 50px;
    width: 50px;
    rotate: 16deg;
    z-index: 99999;
}

.HeaderIconContainer {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1%;
    z-index: 99999;
    cursor:pointer;
}

.HeaderMenuSVG {
    height: 100%;
    width: 5vh;
    /* padding-right: 5vw; */
    z-index: 99999;
}

.HeaderMenuSVG:hover {
    scale: 110%;
}

.HeaderMenu {
    width: 50vw;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    background-color: rgba(var(--bg-values-2), 0.5);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border-width: 1px;
    border-color: var(--cta-color);
    border-style: solid;
    z-index: 99999;
}

.HeaderMenuButton {
    height: 30%;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: var(--cta-color);
    font-size: 150%;
    background-color: transparent;
    border: unset;
    z-index: 99999;

    p {
        padding: 0;
        margin: 0;
    }
}

.HeaderMenuButton:hover {
    scale: 110%;
}

.HeaderMenuHref {
    color: inherit;
    text-decoration: inherit;
}
