.HomeContainer {
    margin-top: 10vh;
    margin-bottom: 8vh;
    min-height: 82dvh;
	width: 100vw;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        margin-top: 20vh;
        flex-direction: column;
    }
}

.HomeHeroTextContainer {
    position: relative;
    /* height: 40%; */
    width: 40%;
    margin: auto;
    opacity: 0; /* Initially hidden */
    transform: translateX(-100%); /* Start from the left */
    animation: slideInFromLeft 0.7s ease-out forwards; /* 0.5s animation */
    z-index: 2;

    @media (max-width: 768px) {
        width: 90vw;
    }

    h1 {
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        font-size: 3.5rem;

        @media (max-width: 768px) {
            font-size: 2.5rem;
        }
    }

    h2 {
        margin-top: 0;
        text-align: left;
        font-size: 1.5rem;

        @media (max-width: 768px) {
            font-size: 1.25rem;
        }
    }

    .GetStartedDropDownOptionsContainer {
        position: absolute;
        bottom: 0;
        /* left: 0; */
        width: 225%;
        margin-top: 1vh;
        padding: 3px;
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 6px;
        background-color: rgba(var(--bg-values-2), 1);
        /* border-style: solid;
        border-width: 1px;
        border-color: var(--cta-color); */
        border-radius: 0.5rem;

        @media (max-width: 768px) {
            width: 100%;
            grid-template-columns: auto;
        }

        span {
            vertical-align: super;
            font-size: smaller;
        }

        h3 {
            padding-top: 0;
            margin-top: 0.5rem;
            margin-bottom: 0;
            color: var(--cta-color)
        }

        p {
            padding-top: 0;
            padding-left: 3px;
            padding-right: 3px;
            margin-top: 0.5rem;
        }

        img {
            width: 75px;
            object-fit: contain;
        }

        .GetStartedOptionsContainer {
            display: grid;
            grid-template-rows: auto auto;
            cursor: pointer;
            border-radius: 0.5rem;
        }

        .GetStartedOptionsContainer:hover {
            background-color: rgba(var(--bg-values), 1);
        }

        .GetStartedOptionsContainerCentral {
            display: grid;
            grid-template-rows: auto auto;
            cursor: pointer;
            border-left: 1px solid var(--text-color-3);
            border-right: 1px solid var(--text-color-3);
        }

        .GetStartedOptionsContainerCentral:hover {
            background-color: rgba(var(--bg-values), 1);
        }
    }
}

@keyframes slideInFromLeft {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.ButtonContainer {
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .ButtonSecondary {
        margin-left: 1vw;

        @media (max-width: 768px) {
            margin-left: unset;
            margin-top: 1vh;
        }
    }
}

.HomeHeroImg {
    position: relative;
    height: 60vh;
    width: 40vw;
    margin: auto;
    border-style: none;
    border-radius: 2rem;
    overflow: hidden;
    opacity: 0;
    transform: translateY(100%);
    animation: slideInFromBottom 0.7s ease-out forwards;

    @media (max-width: 768px) {
        margin-top: 3vh;
        width: 90vw;
    }

    .VideoBackgroundContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        pointer-events: none;
    }
      
    .VideoBackground {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200%;
        height: 200%;
        object-fit: cover;
        pointer-events: none;
    }
      
    .Dimmer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    .ChartWrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .ChartContainer {
        position: absolute;
        width: 70%;
        height: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 5s ease-in-out;
    }

    .ChartOne {
        z-index: 2;
        opacity: 1;
        animation: swap 11s ease-in-out infinite;
    }

    .ChartTwo {
        z-index: 1;
        opacity: 0.5;
        transform: translate(25%, 25%);
        animation: swap-reverse 11s ease-in-out infinite;
    }
}

@keyframes slideInFromBottom {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes swap {
    0%, 25% {
        z-index: 2;
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    50%, 75% {
        z-index: 1;
        opacity: 0.5;
        transform: translate(25%, 25%);
    }
    100% {
        z-index: 1;
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

@keyframes swap-reverse {
    0%, 25% {
        z-index: 1;
        opacity: 0.5;
        transform: translate(25%, 25%);
    }
    50%, 75% {
        z-index: 2;
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    100% {
        z-index: 2;
        opacity: 0.5;
        transform: translate(25%, 25%);
    }
}
