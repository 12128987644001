.WaitlistMessageContainer {
    height: 100vh;
    width: 50vw;
    margin: auto;
    margin-top: 5vh;
    padding-top: 5vh;

    @media (max-width: 768px) {
        width: 80vw;
    }

    h3 {
        padding-top: 3vh;
    }

    p {
        text-align: justify;
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }

    div {
        margin-top: 5vh;
        padding-top: 5vh;
    }
}