.ExpensePieContiner {
    position: relative;
    height: 50vh;
    width: 38vw;
    margin: auto;
    border-radius: 2rem;
    background: linear-gradient(225deg, rgba(var(--accent-values-1), 0.5) 0%, transparent 30%);
    
    @media (max-width: 768px) {
        height: 40vh;
        width: 90vw;
        margin: auto;
        margin-bottom: 2vh;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: linear-gradient(210deg, rgba(var(--accent-values-1), 1) 0%, transparent 50%);
        border-radius: inherit;
        padding: 1px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}