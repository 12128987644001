.DashboardDateRangePicker {
    width: 100vw;
    margin-bottom: 2vh;

    .DashboardDateRangePickerControls {
        width: 40%;
        display: grid;
        grid-template-columns: min-content auto min-content;
        margin: auto;

        @media (max-width: 768px) {
            width: 70%;
        }

        .SelectContainer {
            width: 80%;
            margin: auto;
        }
    }
}