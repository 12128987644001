.MarketContainer {
    width: 96vw;
    margin: 2vh auto;
    background-color: var(--bg-color-2);
    border: 1px solid var(--text-color-3);
    border-radius: 1rem;
}

.MarketContainerSubtitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    p {
        width: 33%;
        font-size: 0.8rem;
        color: var(--text-color-2);
    }
}

.AllMarketPlaceLoanContainer {
    max-height: 35vh;
    overflow: scroll;
}

.MarketPlaceLoanContainer {
    margin: 1vh 1vw;
    background-color: rgba(var(--bg-values), 0.75);
    border: 1px solid var(--text-color-3);
    border-radius: 1rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(var(--bg-values), 0.25);
    }
}

.MarketPlaceLoanPrimaryInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    p {
        width: 33%;
    }
}

.MarketPlaceLoanSecondaryInfoContainer {

}

.MarketPlaceLoanMiscInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    p {
        width: 50%;
        font-size: 0.9rem;
        color: var(--text-color-2);
    }
}