.FaqContainer {
    height: 100%;
	min-height: 100vh;
    width: 100%;
    margin: auto;
    padding-top: 8vh;

    color: var(--text-color);
}

.QAContainer {
    margin-top: 5%;
    margin-bottom: 5%;
}

.QContainer {
    width: 80%;
    display: flex;
    flex-direction: column;

    margin: auto;

    font-size: 1.5rem;
    font-weight: bold;

    border-width: 1px;
    border-radius: 8px;
    border-color: var(--cta-color);
    border-style: solid;
}

.QAQArrowIconSVG {
    height: 100%;
    min-height: 50px;
    width: 50px;
    /* margin: auto; */
}

.QAQArrowIconSVG.flipped {
    rotate: 180deg;
}

.AContainer {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2rem;
    text-align: left;

    @media (max-width: 768px) {
		width: 80%;
        font-size: 1rem;
	}
}

.FAQInputOption {
    height: 100%;
    min-height: 50px;
    width: 80%;
    margin: auto;
    margin-top: 5%;
  
    text-align: center;

    background-color: var(--bg-color);
  
    color: var(--text-color);
    font-size: 1.2rem;
  
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: var(--text-color);
}