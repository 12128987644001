.ManageLoanContainer {
    min-height: 100vh;
    color: var(--text-color);

    .TakeOutLoanButton {
        height: 4vh;
        width: 15rem;
        cursor: pointer;
        background-color: transparent;
        color: var(--cta-color);
        border-style: solid;
        border-width: 1px;
        border-color: var(--cta-color);
        border-radius: 3rem;
        font-size: 1.5rem;
        font-family: var(--font-focus);
    }

    .TakeOutLoanButton:hover{
        scale: 110%;
    }

    .ManageLoanView {

        .LoanView {
            min-height: 40vh;
            width: 80vw;
            margin-top: 2vh;
            margin-bottom: 2vh;
            margin-left: auto;
            margin-right: auto;
            border: none;
            border-radius: 3rem;
            box-shadow: -1px -1px 4px 2px var(--bg-color-2), 1px 1px 4px 2px var(--bg-color-2);
            /* background-color: hsl(139, 48%, 5%); */
            background-color: hsl(190, 33%, 10%);

            .LoanViewTitle {
                padding-top: 2vh;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            .LoanViewStartingOn {
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;

            }

            .LoanViewTotalInterest {
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;

                .LoanViewTotalInterestSpan {
                    font-size: 0.7rem;
                }
            }

            .DataContainer {
                display: flex;
                flex-direction: row;
                width: 50vw;
                margin-top: 2vh;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;

                @media (max-width: 768px) {
                    flex-direction: column-reverse;
                    width: 80vw;
                }

                .ChartContainer {
                    height: 20vh;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
    
                    @media (max-width: 768px) {
                        height: 30vh;
                    }
                }
    
                .BorrowerLoanCarouselContainer {
                    padding-bottom: 2vh;
                    cursor: pointer;
    
                    .carousel {
                        position: relative;
                        overflow: hidden;
                        width: 20vw;
                        @media (max-width: 768px) {
                            width: 80vw;
                        }
                    }
                    
                    .carousel-inner {
                        display: flex;
                        transition: transform 0.5s ease-in-out;
                    }
                    
                    .carousel-item {
                        min-width: 100%;
                        flex-shrink: 0;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        margin-bottom: 2vh;
    
                        h3 {
                            text-align: right;
                            margin-right: 1vw;
                        }
    
                        p {
                            text-align: left;
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-left: 1vw;
                        }
                    }
    
                    .carousel-item > :nth-child(1) {
                        grid-column: 1 / -1;
                        justify-self: center;
                    }
                    
                    .carousel-dots {
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                    }
                    
                    .carousel-dot {
                        height: 0.7rem;
                        width: 0.7rem;
                        margin: 0 0.75rem;
                        background-color: var(--bg-color-2);
                        border-radius: 50%;
                        display: inline-block;
                        cursor: pointer;
                    }
                    
                    .carousel-dot.active {
                        background-color: var(--cta-color);
                    }
    
                    .carousel-dot:hover {
                        scale: 150%;
                    }
                }
            }

            .AdditionalPaymentButton {
                height: 4vh;
                width: 15rem;
                margin-top: 2vh;
                margin-bottom: 5vh;
                cursor: pointer;
                background-color: transparent;
                color: var(--cta-color);
                border-style: solid;
                border-width: 1px;
                border-color: var(--cta-color);
                border-radius: 3rem;
                font-size: 1.2rem;
                font-family: var(--font-focus);
        
                @media (max-width: 768px) {
                    width: 90%;
                }
            }
        
            .AdditionalPaymentButton:hover {
                scale: 110%;
            }
        }
    }
}

.AdditionalPaymentsModal {
    position: fixed;
    width: 50%;
    translate: 0% 40%;
    background-color: var(--bg-color);
    border-style: none;
    border-radius: 3rem;
    box-shadow: -1px -1px 8rem 6px var(--bg-color-2), 1px 1px 8rem 6px var(--bg-color-2);
    z-index: 99999;

    @media (max-width: 768px) {
        width: 80%;
        translate: 0% 25%;
    }

    .AdditionalPaymentsModalInsideContainer {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        color: var(--text-color);

        @media (max-width: 768px) {
            width: 90%;
        }

        .TitleExitContainer {
            display: flex;
            flex-direction: row-reverse;

            .Close {
                width: 2rem;
                margin-left: auto;
                background: transparent;
                border: none;
                color: var(--neg-color);
                font-size: 1rem;
                cursor: pointer;
            }
    
            .Close:hover {
                scale: 110%;
            }
        }

        .detailsContainer {
            margin-top: 2vh;
            margin-bottom: 5vh;
        }

        .AdditionalPaymentInput {
            height: 3rem;
            width: 100%;
            appearance: none;
            cursor: pointer;
            box-sizing: border-box;
            border: none;
            padding: 0;
            margin: 0;
            -webkit-border-radius: 0;
            -moz-appearance: textfield;
    
            color: var(--text-color);
            font-size: 1.5rem;
            text-align: center;
    
            border-style: none;
            background-color: transparent;
            border-style: none;
            border-bottom: 2px solid var(--bg-color-2);
    
            /* mobile */
            @media (max-width: 768px) {
                font-size: 1rem;
            }
        }
    
        .AdditionalPaymentInput:focus {
            outline: none; /* Removes the default focus outline */
            border-bottom: 2px solid var(--cta-color); /* Example: Changes bottom border color on focus */
        }
    
        .AdditionalPaymentInput::-webkit-outer-spin-button,
        .AdditionalPaymentInput::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .AdditionalPaymentButton {
            height: 4vh;
            width: 15rem;
            margin-top: 5vh;
            margin-bottom: 3vh;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            background-color: transparent;
            color: var(--cta-color);
            border-style: solid;
            border-width: 1px;
            border-color: var(--cta-color);
            border-radius: 3rem;
            font-size: 1.5rem;
            font-family: var(--font-focus);
        }

        .AdditionalPaymentButton:hover {
            background-color: var(--bg-color-2);
            scale: 110%;
        }

        .AdditionalPaymentButton:disabled {
            background-color: var(--neg-color);
            scale: 90%;
        }
        
    }
}

.DashboardContainer {
    min-height: 100vh;
    margin-top: 8dvh;
    margin-bottom: 8dvh;
    overflow: hidden;

    @media (max-width: 768px) {
        margin-top: 10dvh;
    }
}

.HighOverviewListContainer {
    min-height: fit-content;
    width: 100vw;
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 2fr;
    margin: auto;
    margin-bottom: 5vh;

    @media (max-width: 768px) {
        height: 30vh;
        width: 100vw;
        grid-template-columns: auto;
    }
}

.PieAndTransactionsContainer {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 5vh;

    @media (max-width: 768px) {
        grid-template-columns: auto;
    }
}
