.MarketFilterContainer {
    z-index: 999;
    position: absolute;
    width: 100%;
    padding-top: 2vh;
    opacity: 1;
    background-color: var(--bg-color);
    border: 1px solid var(--text-color-3);
    border-radius: 1rem;

    @media (max-width: 768px) {
        width: 215%;
    }

    h3 {
        text-align: left;
        padding: 0;
        margin: 0;
    }
}

.FilterCriteriaSection {
    width: 80%;
    margin: auto;
    margin-bottom: 3vh;
}