.ResetPasswordContainer {
    height: 100vh;

    .ResetPasswordFormContainer {
        position: absolute;
        top: 25%;
        width: 100vw;

        @media (max-width: 768px) {
            top: 15%;
        }

        .ForgotPasswordTitle {
            color: var(--text-color);
            font-size: 3rem;
        }

        .FormInputsContainer {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            .Input {
                height: 5vh;
                margin-bottom: 5vh;
                color: var(--text-color);
                font-size: 1.5rem;
                background-color: transparent;
                border-style: none;
                border-bottom: 2px solid var(--bg-color-2);
            }
        
            .Input:focus {
                outline: none; /* Removes the default focus outline */
                border-bottom: 2px solid var(--cta-color); /* Example: Changes bottom border color on focus */
            }
        
            .Submit {
                width: 50%;
                background-color: transparent;
                margin-left: auto;
                margin-right: auto;
                font-size: 2rem;
                color: var(--cta-color);
                border-style: none;
                border-radius: 3rem;
                cursor:pointer;
        
                @media (max-width: 768px) {
                    width: 90%;
                }
            }
    
            .Submit:disabled {
                color: var(--neg-color);
            }
    
            .Submit:hover {
                scale: 110%;
            }
        }
    }
}