.ExpenseTimeline {
    position: relative;
    height: 40vh;
    width: 90vw;
    overflow: hidden;
    margin: auto;
    border-radius: 2rem;
    background: linear-gradient(225deg, rgba(var(--accent-values-2), 0.5) 0%, transparent 30%);
    
    @media (max-width: 768px) {
        height: 40vh;
        width: 90vw;
        margin: auto;
        margin-top: 3vh;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: linear-gradient(210deg, rgba(var(--accent-values-2), 1) 0%, transparent 70%);
        border-radius: inherit;
        padding: 1px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}

.customTooltip {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}
  
.label {
    font-weight: bold;
}
  
.tooltipList {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.tooltipList li {
    margin: 4px 0;
}