.FutureHeroContainer {
    background-color: var(--bg-color-2);
    padding-bottom: 5vh;
}

.HeadingContainer {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5vh;
}

.FeatureSectionContainer {
    min-height: 86vh;
    margin-bottom: 8vh;
}

.ToggleContainer {
    width: 80%;
    margin: auto;
}

.DetailsContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
        margin-top: 0vh;
    }

    .PointsContainer {
        width: 50%;
        margin: auto;
        text-align: left;

        @media (max-width: 768px) {
            width: 90%;
        }

        h3 {
            padding-top: 1.5vh;
            margin-bottom: 0;
            padding-bottom: 5px;
            font-size: 1.5rem;

            @media (max-width: 768px) {
                padding-top: 0.5vh;
            }
        }

        p {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 1rem;
        }

        sup {
            font-size: 0.6rem;
        }

        small {
            margin: 0;
            padding: 0;
            font-size: 0.6rem;
        }

        ul {
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    img {
        height: auto;
        width: 40%;
        margin: auto;
        object-fit: cover;
        border-radius: 1rem;

        @media (max-width: 768px) {
            width: 80%;
            margin-top: 5vh;
        }
    }
}

.fadeIn {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fadeOut {
    opacity: 0;
    transform: translateY(100);
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
