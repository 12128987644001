.TransactionTableContainer {
    position: relative;
    height: 50vh;
    width: 50vw;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2vh;
    border-radius: 2rem;
    background: linear-gradient(225deg, rgba(var(--accent-values-3), 0.5) 0%, transparent 30%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: linear-gradient(210deg, rgba(var(--accent-values-3), 1) 0%, transparent 50%);
        border-radius: inherit;
        padding: 1px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    @media (max-width: 768px) {
        height: 60vh;
        width: 90vw;
        margin: auto;
    }

    .TransactionTableTransactions {
        height: 80%;
        overflow: scroll;

        .TransactionTableTransactionContainer {
            position: relative;
            max-height: 10vh;
            display: grid;
            grid-template-columns: min-content 33% auto;
            padding: 2vw;
    
            .TransactionLogo {
                height: 8vh;
                width: 8vh;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2vw;
                margin-right: 2vw;
                border-radius: 50%;
    
                img {
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                    object-fit: scale-down;
                    border-radius: 50%;
                }
            }
    
            .TransactionData {
                height: min-content;
                margin-top: auto;
                margin-bottom: auto;
                text-align: left;
    
                h3 {
                    margin: 0;
                    padding: 0;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
    
                h4 {
                    margin: 0;
                    padding: 0;
                }
    
                p {
                    margin: 0;
                    padding: 0;
                    color: var(--text-color-2);
                }
            }
        }

        .TransactionTableTransactionContainer:not(:last-child)::after {
            content: "";
            position: absolute;
            bottom: 0; /* Align the bottom border at the bottom of the parent */
            left: 5%; /* Center the border horizontally by offsetting it by 10% on the left */
            width: 90%; /* The width of the border */
            border-bottom: 2px solid var(--text-color-3); /* The bottom border itself */
        }
    }
}