.CriteriaContainer {
    width: 80vw;
    margin: auto;
    margin-bottom: 5vh;
    padding: 1vh 2vw;
    border-radius: 1rem;
    background-color: var(--bg-color);
    scroll-snap-align: start;
}

.ViewContainer {
    width: 100%;

    @media (max-width: 768px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

.CriteriaHeading {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 1vh;

    h2 {
        padding: 0;
        margin: 0;
        text-align: right;
        color: var(--text-color-2);
    }

    h3 {
        padding: 0;
        margin: 0;
        text-align: right;
    }

    button {
        margin-left: auto;
        cursor: pointer;
        border: 0 none transparent;
        background-color: transparent;
    }

    button:hover{
        scale: 110%;
    }
}

.LoanCriteriaSettingsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3vw;
    margin-top: 3vh;
    /* overflow: hidden; */

    @media (max-width: 768px) {
        max-height: 40vh;
        overflow: scroll;
        grid-template-columns: 1fr;
        row-gap: 1.5vh;

        border: 1px solid var(--text-color-3);
        border-radius: 1rem;
        background-color: var(--bg-color-2);
    }

    h2 {
        padding: 0;
        margin: 0;
    }

    h3 {
        padding: 0;
        padding-top: 0.5vh;
        margin: 0;
        color: var(--text-color-2);
        text-align: left;
    }

    span {
        color: var(--text-color);
    }
}

.LabelContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h2 {
        padding: 0;
        margin: 0;
    }

    span {
        font-size: 0.8rem;
        color: var(--text-color-3);
    }
}
