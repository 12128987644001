.toggle-switch {
    position: relative;
    /* width: 12rem; */
    /* height: 3vh; */
    height: 100%;
    width: 100%;
    margin-top: 1vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vh;
    background-color: var(--bg-color-2);
    border-style: solid;
    border-width: 1px;
    border-color: var(--text-color-3);
    border-radius: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    position: relative;
}

.toggle-switch .slider {
    position: absolute;
    height: 100%;
    background-color: var(--bg-color);
    border-style: solid;
    border-width: 1px;
    border-color: var(--text-color-2);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color-1);
    font-weight: bold;
    transition: all 0.8s ease;
    box-sizing: border-box;
    /* Clip overflow */
    overflow: hidden;
    white-space: nowrap;
}

.toggle-switch .labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    text-align: center;
    z-index: 1;
}

.toggle-switch .label {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color-2);
    font-weight: bold;
    transition: color 0.15s ease;
}

.toggle-switch .label.active {
    color: transparent;
}
  