.Container {
    min-height: 80vh;
    padding-top: 8vh;
    padding-bottom: 8vh;
    background-color: var(--bg-color-2);

    h1 {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 3rem;
        text-shadow: -1px -1px 0 var(--bg-color-2), 1px -1px 0 var(--bg-color-2), -1px 1px 0 var(--bg-color-2), 1px 1px 0 var(--bg-color-2);

        @media (max-width: 768px) {
            font-size: 1.8rem
        }
    }


    h2 {
        color: var(--text-color);
        font-size: 2rem;
        font-style: italic;
        text-shadow: -1px -1px 0 var(--bg-color-2), 1px -1px 0 var(--bg-color-2), -1px 1px 0 var(--bg-color-2), 1px 1px 0 var(--bg-color-2);

        @media (max-width: 768px) {
            font-size: 1.3rem
        }
    }
}

.CarouselContainer {
    cursor: pointer;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;

    .carousel {
        overflow: hidden;
        /* height: 35vh; */
        width: 80vw;
        backdrop-filter: blur(10px);
        background-color: rgb(0, 0, 0, 0.3);
        border-style: none;
        border-radius: 3rem;
    }
    
    .carouselinner {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }
    
    .carouselitem {
        height: 30vh;
        min-width: 100%;
        margin-bottom: 2vh;

        h3 {
            text-align: center;
            font-size: 1.75rem;
            padding-bottom: 0;
            margin-bottom: 0;
            text-shadow: -1px -1px 0 var(--bg-color-2), 1px -1px 0 var(--bg-color-2), -1px 1px 0 var(--bg-color-2), 1px 1px 0 var(--bg-color-2);

            @media (max-width: 768px) {
                font-size: 1.2rem;
            }
        }

        p {
            text-align: left;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2vw;
            margin-right: 2vw;
            font-size: 1.5rem;
            text-shadow: -1px -1px 0 var(--bg-color-2), 1px -1px 0 var(--bg-color-2), -1px 1px 0 var(--bg-color-2), 1px 1px 0 var(--bg-color-2);

            @media (max-width: 768px) {
                font-size: 1rem;
            }
        }

        img {
            width: 18%;
            /* object-fit: cover; */
            border-radius: 1rem;
            border-style: none;
            background-color: transparent;

            @media (max-width: 768px) {
                position: absolute;
                top: 0;
                z-index: -1;
                width: 80vw;
                opacity: 0;
            }
        }
    }

    .cardcontent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 2vw;
        margin-right: 2vw;
    }

    .carouselitem > :nth-child(1) {
        grid-column: 1 / -1;
        justify-self: center;
    }
    
    .carouseldots {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: row;
    }
}

.carouseldot {
    position: relative;
    height: 0.7rem;
    width: 0.7rem;
    margin: 0 0.75rem;
    background-color: var(--bg-color-2);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;
    clip-path: circle(50%);
    z-index: 1;

    @media (max-width: 768px) {
        margin: 0 0.5rem;
    }
}

.carouseldot:hover {
    scale: 110%;
    background-color: var(--cta-color);
}

.carouseldot.active {
    background-color: var(--cta-color);
}

/* .carouseldot.active::before,
.carouseldot.active::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--cta-color);
} */

/* Left half of the circle */
/* .carouseldot.active::before {
    clip: rect(0px, 0.35rem, 0.7rem, 0px);
    animation: leftFill 4s linear forwards;
} */

/* Right half of the circle */
/* .carouseldot.active::after {
    clip: rect(0px, 0.7rem, 0.7rem, 0.35rem);
    animation: rightFill 4s linear forwards 4s;
}

@keyframes leftFill {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rightFill {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} */


.fadeIn {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fadeOut {
    opacity: 0;
    transform: translateY(100);
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}