.CarouselContainer {
    padding-bottom: 2vh;
    cursor: pointer;

    .Carousel {
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    
    .CarouselInner {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }
    
    .CarouselItem {
        min-width: 100%;
        flex-shrink: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2vh;
    }

    .CarouselItem > :nth-child(1) {
        grid-column: 1 / -1;
        justify-self: center;
    }
    
    .CarouselDots {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
    }
    
    .CarouselDot {
        height: 0.7rem;
        width: 0.7rem;
        margin: 0 0.75rem;
        background-color: var(--bg-color-2);
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
    }
    
    .CarouselDot.Active {
        background-color: var(--cta-color);
    }

    .CarouselDot:hover {
        scale: 150%;
    }
}