.AccountManagementContainer {
    min-height: 100vh;
    color: var(--text-color);
    margin-top: 9dvh;

    .DataInputContainer {

        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vh;
        padding-top: 5vh;

        .DataInputSection {
            width: 80%;
            margin: auto;
            display: flex;
            flex-direction: row;
            align-items: end;
            margin-top: 2vh;
            margin-bottom: 2vh;
        }

        .DataInputTitle {
            width: 30%;

            padding-right: 5vw;
            font-size: 1.3rem;
            color: var(--text-color);
            text-align: right;

            @media (max-width: 768px) {
                font-size: 1rem;
            }
        }

        .DataInput {
            height: 3rem;
            width: 50%;
            cursor: pointer;
            background-color: transparent;
            box-sizing: border-box;
            border: none;
            padding: 0;
            margin: 0;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            -moz-appearance: textfield;
    
            color: var(--text-color);
            font-size: 1.5rem;
            text-align: center;
            text-align-last: center;
    
            border-style: none;
            background-color: transparent;
            border-style: none;
            border-bottom: 2px solid var(--bg-color-2);
    
            /* mobile */
            @media (max-width: 768px) {
                font-size: 1rem;
            }
        }

        .DataInput:focus {
            outline: none; /* Removes the default focus outline */
            border-bottom: 2px solid var(--cta-color); /* Example: Changes bottom border color on focus */
        }

        .DataInput::-webkit-outer-spin-button,
        .DataInput::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .AllLendersLicenseContainer {
            /* display: flex;
            flex-direction: row; */
            width: 50vw;
            max-height: 80vh;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 2vh;
            margin-left: auto;
            margin-right: auto;
            padding-left: 1vw;
            padding-right: 1vw;
            @media (max-width: 768px) {
                width: 80vw;
            }
        }

        .LicenseDataContainer {
            display: flex;
            flex-direction: column;
            margin-top: 2vh;
            margin-bottom: 2vh;
            border-style: hidden;
            border-radius: 3rem;
            box-shadow: 1px 1px 10px 1px var(--bg-color-2);
        }

        .LicenseDataInput {
            height: 3rem;
            width: 80%;
            cursor: pointer;
            background-color: transparent;
            box-sizing: border-box;
            border: none;
            padding: 0;
            margin: 0;
            margin-left: auto;
            margin-right: auto;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            -moz-appearance: textfield;
    
            color: var(--text-color);
            font-size: 1.5rem;
            
            text-align: right;
            text-align-last: center;
    
            border-style: none;
            background-color: transparent;
            border-style: none;
            border-bottom: 2px solid var(--bg-color-2);
    
            /* mobile */
            @media (max-width: 768px) {
                font-size: 1rem;
            }
        }

        .LicenseDataInput::placeholder {
            opacity: 0.5;
            color: gray;
        }

        .LicenseDataInput:focus {
            outline: none; /* Removes the default focus outline */
            border-bottom: 2px solid var(--cta-color); /* Example: Changes bottom border color on focus */
        }

        .DataLicenseButton {
            /* width: 15rem; */
            background-color: transparent;
            margin-top: 2vh;
            margin-bottom: 2vh;
            margin-left: auto;
            margin-right: auto;
            font-size: 1rem;
            color: var(--neg-color);
            border-style: none;
            border-radius: 3rem;
            cursor: pointer;
    
            border-style: hidden;
            border-radius: 3rem;
            box-shadow: 1px 1px 10px 1px var(--pos-color);
    
            @media (max-width: 768px) {
                /* width: 90%; */
                font-size: 1.4rem;
            }
        }

        .DataLicenseButton:hover {
            scale: 110%;
        }

        .DataButton {
            width: 15rem;
            background-color: transparent;
            margin-top: 2vh;
            margin-left: auto;
            margin-right: auto;
            font-size: 1.2rem;
            color: var(--text-color);
            border-style: none;
            border-radius: 3rem;
            cursor: pointer;
    
            border-style: hidden;
            border-radius: 3rem;
            box-shadow: 1px 1px 16px 4px var(--pos-color);
    
            @media (max-width: 768px) {
                width: 90%;
                font-size: 1.1rem;
            }
        }

        .DataButton:hover {
            scale: 110%;
        }

        .DataSaveButton {
            width: 15rem;
            background-color: transparent;
            margin-top: 8vh;
            margin-left: auto;
            margin-right: auto;
            font-size: 1.5rem;
            font-weight: bold;
            font-family: var(--font-focus);
            color: var(--cta-color);
            cursor: pointer;
            border-style: solid;
            border-width: 1px;
            border-color: var(--cta-color);
            border-radius: 3rem;

            @media (max-width: 768px) {
                width: 90%;
                font-size: 1.4rem;
            }
        }

        .DataSaveButton:disabled {
            color: var(--neg-color);
            border-color: var(--neg-color);
            box-shadow: 1px 1px 16px 4px var(--neg-color);
        }

        .DataSaveButton:hover {
            scale: 110%;
            /* box-shadow: 1px 1px 16px 4px var(--cta-color); */
        }
    }

}