body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html {
	--bg-color: rgb(16, 16, 16);
	--bg-values: 16, 16, 16;

	--bg-color-2: rgb(29, 29, 29);
	--bg-values-2: 29, 29, 29;

	--cta-color: rgb(255, 209, 85);
	--cta-values: 255, 209, 85;

	--accent-color-1: rgb(78, 163, 255);
	--accent-values-1: 78, 163, 255;

	--accent-color-2: rgb(0, 194, 155);
	--accent-values-2: 0, 194, 155;

	--accent-color-3: rgb(253, 199, 153);
	--accent-values-3: 253, 199, 153;

	--accent-color-4: rgb(0, 123, 137);
	--accent-values-4: 0, 123, 137;

	--accent-color-5: rgb(234, 85, 162);
	--accent-values-5: 234, 85, 162;

	--accent-color-6: rgb(204, 52, 44);
	--accent-values-6: 204, 52, 44;

	--accent-color-7: rgb(166, 77, 255);
	--accent-values-7: 166, 77, 255;

	--accent-color-8: rgb(255, 121, 63);
	--accent-values-8: 255, 121, 63;

	--accent-color-9: rgb(255, 94, 131);
	--accent-values-9: 255, 94, 131;

	--accent-color-10: rgb(128, 169, 56);
	--accent-values-10: 128, 169, 56;

	--accent-color-11: rgb(0, 178, 169);
	--accent-values-11: 0, 178, 169;

	--accent-color-12: rgb(255, 204, 102);
	--accent-values-12: 255, 204, 102;

	--accent-color-13: rgb(144, 190, 255);
	--accent-values-13: 144, 190, 255;

	--accent-color-14: rgb(76, 217, 100);
	--accent-values-14: 76, 217, 100;

	--accent-color-15: rgb(64, 196, 255);
	--accent-values-15: 64, 196, 255;

	--accent-color-16: rgb(193, 119, 255);
	--accent-values-16: 193, 119, 255;

	--accent-color-17: rgb(0, 156, 222);
	--accent-values-17: 0, 156, 222;

	--pos-values: 69, 196, 111;
	--pos-color: rgb(69, 196, 111);

	--neg-color: rgb(211, 78, 36);
	--neg-values: 211, 78, 36;

	background-color: transparent;

	--text-color: rgb(254, 254, 254);
	--text-values: 254, 254, 254;

	--text-color-2: rgb(136, 136, 136);
	--text-values-2: 254, 254, 254, 0.75;

	--text-color-3: rgb(70, 70, 70);
	--text-values-3: 254, 254, 254, 0.2;

	--font-focus: Galada;
	--font-regular: Helvetica;

	color: var(--text-color);
	
	-ms-overflow-style: none;
	scrollbar-width: none;
}

::-webkit-scrollbar {
	display: none;
}

@font-face {
	font-family: 'Galada';
	src: local('Galada'), url(./fonts/Galada/Galada-Regular.ttf) format('opentype');
}

label {
    margin: auto;
    font-size: 1.2rem;

    @media (max-width: 768px) {
		font-size: 0.9rem;
	}
}