.DisclosuresPage {
    min-height: 82dvh;
    margin-top: 9dvh;
    color: var(--text-color);
}

.DisclosureContainer {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;

    p {
        text-align: justify;
    }
}